import React from "react";

import { DatePicker } from "antd";

export const AntDatePicker = (props) => {
  return (
    <DatePicker
      {...props.datePickerProps}
      getPopupContainer={(node) => node.parentNode}
    />
  );
};
