import React from "react";

import PhoneInput from "react-phone-input-2";

import {
  ButtonComponent,
  ModalComponent,
} from "../../helper/ReusableComponents";
import headerStyles from "../HeaderComponent/Header.module.css";
import FBLoginComponent from "../SocialLoginComponent/FBLoginComponent";
import GoogleLoginComponent from "../SocialLoginComponent/GoogleLoginComponent";

export default function LoginWithOtpComponent(props) {
  return (
    <ModalComponent
      className={headerStyles.account_modal}
      data={{
        visible: props.visible,
        onCancel: props.onCancelModal,
        footer: null,
        className: "forget_password_modal add_user_modal",
      }}
    >
      <div className={headerStyles.account_modal_banner}>
        <img
          src="/assets/images/modal_banner.png"
          alt="account-banner"
          width={280}
          height={480}
        />
      </div>
      <div
        className={`d-flex flex-column ${headerStyles.account_modal_content}`}
      >
        <h2 className={headerStyles.account_modal_heading}>Login with OTP</h2>
        <p className={headerStyles.account_modal_option}>
          Enter your registered phone Number
        </p>
        <form onSubmit={props.onGetOtp} data-test="login_form">
          {/* <p>Enter your registered phone number</p> */}
          <div className={headerStyles.input_section}>
            <div className={headerStyles.input_field}>
              {/* <InputComponent
                      data-test="user_phone"
                      formControl={{
                        id: "user_phone",
                        placeholder: "Phone*",
                        onChange: props.onInputChange,
                        name: "phone",
                        type: "text",
                        "aria-describedby": "user_phone",
                         value: props.signUpForm ? props.signUpForm.phone:""
                      }}
                    /> */}
              <PhoneInput
                inputProps={{
                  name: "phone",
                  placeholder: "Phone *",
                }}
                // countryCodeEditable={false}
                disableCountryCode={true}
                // disableDropdown={true}
                // enableSearch
                // autoFormat={false}
                country={"us"}
                onlyCountries={["us"]}
                value={
                  props.signUpForm &&
                  props.signUpForm.phone &&
                  props.signUpForm.phone.number
                }
                onChange={props.onPhoneInputChanges}
              />
            </div>
            <span className="error" data-test="username_error">
              {props.formError && props.formError.phone !== ""
                ? props.formError.phone
                : ""}
            </span>
          </div>
          <div className="d-flex justify-content-between flex-wrap">
            <p className={headerStyles.account_modal_option_link}>
              <span className={headerStyles.account_modal_option_link_text}>
                Continue to
              </span>
              <a onClick={props.openModel}>
                <u>Login</u>
              </a>
            </p>
            <ButtonComponent
              data-test="sign_in_btn"
              data={{
                type: "submit",
                className: headerStyles.login_btn,
              }}
            >
              Send OTP
            </ButtonComponent>
          </div>
        </form>
        <div
          className={`${headerStyles.account_modal_social} d-flex align-items-center mt-auto justify-content-center`}
        >
          <span className={headerStyles.account_modal_social_text}>
            Or Continue with
          </span>
          {/* <FBLoginComponent /> */}
          <GoogleLoginComponent />
        </div>
      </div>
    </ModalComponent>
  );
}
