import Link from "next/link";
import PhoneInput from "react-phone-input-2";

import {
  InputComponent,
  ButtonComponent,
} from "../../helper/ReusableComponents";
import { AntModalComponent as ModalComponent } from "../../helper/ReusableComponents/AntModalComponent";
import headerStyles from "../HeaderComponent/Header.module.css";
import FBLoginComponent from "../SocialLoginComponent/FBLoginComponent";
import GoogleLoginComponent from "../SocialLoginComponent/GoogleLoginComponent";

const SignUpComponent = (props) => {
  return (
    <ModalComponent
      className={headerStyles.account_modal}
      data={{
        visible: props.visible,
        onCancel: props.onCancelModal,
        footer: null,
        className: "forget_password_modal add_user_modal",
      }}
    >
      <div className={headerStyles.account_modal_banner}>
        <img
          src="/assets/images/modal_banner.png"
          alt="account-banner"
          width={280}
          height={480}
        />
      </div>
      <div
        className={`d-flex flex-column ${headerStyles.account_modal_content}`}
      >
        <h2 className={headerStyles.account_modal_heading}>Create Account</h2>
        <p className={headerStyles.account_modal_option}>
          Already a Client ?{" "}
          <a
            onClick={props.openModel}
            className={` ${headerStyles.account_modal_option_link} ${headerStyles.account_modal_option_link_right} ${headerStyles.account_modal_option_link_upper}`}
          >
            Login
          </a>
        </p>
        <form onSubmit={props.onSignUp} data-test="signup_form">
          <div className={headerStyles.input_section}>
            <div className={headerStyles.input_field}>
              <InputComponent
                data-test="firstName"
                formControl={{
                  id: "firstName",
                  placeholder: "First Name*",
                  onChange: props.onInputChange,
                  name: "firstName",
                  type: "text",
                  "aria-describedby": "firstName",
                  value: props.signUpForm ? props.signUpForm.firstName : "",
                }}
              />
            </div>
            <span className="error" data-test="username_error">
              {props.formError && props.formError.firstName !== ""
                ? props.formError.firstName
                : ""}
            </span>
          </div>
          <div className={headerStyles.input_section}>
            <div className={headerStyles.input_field}>
              <InputComponent
                data-test="lastName"
                formControl={{
                  id: "lastName",
                  placeholder: "Last Name*",
                  onChange: props.onInputChange,
                  name: "lastName",
                  type: "text",
                  "aria-describedby": "lastName",
                  value: props.signUpForm ? props.signUpForm.lastName : "",
                }}
              />
            </div>
            <span className="error" data-test="username_error">
              {props.formError && props.formError.lastName !== ""
                ? props.formError.lastName
                : ""}
            </span>
          </div>
          <div className={headerStyles.input_section}>
            <div className={headerStyles.input_field}>
              <InputComponent
                data-test="user_email"
                formControl={{
                  id: "user_email",
                  placeholder: "Email*",
                  onChange: props.onInputChange,
                  name: "email",
                  type: "text",
                  "aria-describedby": "user_email",
                  value: props.signUpForm ? props.signUpForm.email.trim() : "",
                }}
              />
            </div>
            <span className="error" data-test="username_error">
              {props.formError && props.formError.email !== ""
                ? props.formError.email
                : ""}
            </span>
          </div>
          <div className={headerStyles.input_section}>
            <div className={headerStyles.input_field}>
              <PhoneInput
                inputProps={{
                  name: "phone",
                  placeholder: "Phone *",
                }}
                // countryCodeEditable={false}
                disableCountryCode={true}
                // enableSearch
                // autoFormat={false}
                country={"us"}
                onlyCountries={["us"]}
                value={
                  props.signUpForm &&
                  props.signUpForm.phone &&
                  props.signUpForm.phone.number
                }
                onChange={props.onPhoneInputChanges}
              />
            </div>
            <span className="error" data-test="username_error">
              {props.formError && props.formError.phone !== ""
                ? props.formError.phone
                : ""}
            </span>
          </div>
          <div
            className={`${headerStyles.input_section} ${headerStyles.input_section_password}`}
          >
            <div className={headerStyles.input_field}>
              <InputComponent
                formControl={{
                  placeholder: "Password*",
                  onChange: props.onInputChange,
                  name: "password",
                  type: props.pwd_visible ? "text" : "password",
                  "data-test": "user_password",
                  value: props.signUpForm ? props.signUpForm.password : "",
                }}
              />
              <img
                className={headerStyles.eye_icon}
                src={
                  props.pwd_visible
                    ? "/assets/images/eye_close.svg"
                    : "/assets/images/eye_mini_open.svg"
                }
                alt="eye_icon"
                onClick={props.on_password_visible}
                width={13}
                height={10}
              />
            </div>
            <span className="error" data-test="password_error">
              {props.formError && props.formError.password !== ""
                ? props.formError.password
                : ""}
            </span>
          </div>
          {props.checkRadio ? (
            <>
              <div className={headerStyles.input_section}>
                <div className={headerStyles.input_field}>
                  <InputComponent
                    data-test="companyName"
                    formControl={{
                      id: "companyName",
                      placeholder: "Company Name*",
                      onChange: props.onInputChange,
                      name: "companyName",
                      type: "text",
                      "aria-describedby": "companyName",
                      value: props.signUpForm
                        ? props.signUpForm.companyName
                        : "",
                    }}
                  />
                </div>
                <span className="error" data-test="companyname_error">
                  {props.formError && props.formError.companyName !== ""
                    ? props.formError.companyName
                    : ""}
                </span>
              </div>
              <div className={headerStyles.input_section}>
                <div className={headerStyles.input_field}>
                  <InputComponent
                    data-test="companyLink"
                    formControl={{
                      id: "companyLink",
                      placeholder: "Company Website Link*",
                      onChange: props.onInputChange,
                      name: "companyLink",
                      type: "text",
                      "aria-describedby": "companyLink",
                      value: props.signUpForm
                        ? props.signUpForm.companyLink
                        : "",
                    }}
                  />
                </div>
                <span className="error" data-test="companylink_error">
                  {props.formError && props.formError.companyLink !== ""
                    ? props.formError.companyLink
                    : ""}
                </span>
              </div>
            </>
          ) : (
            ""
          )}

          <div>
            <label className={headerStyles.register_checkbox}>
              <input
                type="checkbox"
                name="isEventPlanner"
                checked={props.checkRadio}
                onChange={() =>
                  props.checkRadio
                    ? props.onChangeRadio(false)
                    : props.onChangeRadio(true)
                }
              />
              <span className={headerStyles.event_planer_check_text}>
                Sign Up as Event Planner
              </span>
            </label>
          </div>
          <div className={headerStyles.input_section_register}>
            <span>By registering you agree to our </span>
            <Link href="/terms-and-conditions">T&C</Link> and{" "}
            <Link href="/privacy-polices">Privacy policy</Link>
          </div>
          <ButtonComponent
            data-test="sign_in_btn"
            data={{
              type: "submit",
              className: "ms-auto " + headerStyles.login_btn,
            }}
          >
            Join Us
          </ButtonComponent>
        </form>
        {!props.checkRadio && (
          <div
            className={`${headerStyles.account_modal_social} d-flex align-items-center mt-auto justify-content-center`}
          >
            <span className={headerStyles.account_modal_social_text}>
              Or Continue with
            </span>
            {/* <FBLoginComponent /> */}
            <GoogleLoginComponent />
          </div>
        )}
      </div>
    </ModalComponent>
  );
};

export default SignUpComponent;
