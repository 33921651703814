import React from "react";

import {
  ModalComponent,
  ButtonComponent,
  InputComponent,
} from "../../helper/ReusableComponents";
import headerStyles from "../HeaderComponent/Header.module.css";
import FBLoginComponent from "../SocialLoginComponent/FBLoginComponent";
import GoogleLoginComponent from "../SocialLoginComponent/GoogleLoginComponent";

const ForgotPasswordComponent = (props) => {
  return (
    <ModalComponent
      className={headerStyles.account_modal}
      data={{
        visible: props.visible,
        onCancel: props.onCancelModal,
        footer: null,
        className: "forget_password_modal add_user_modal",
      }}
    >
      <div className={headerStyles.account_modal_banner}>
        <img
          loading="lazy"
          src="/assets/images/modal_banner.png"
          alt="Starlight"
          width={280}
          height={480}
        />
      </div>
      <div
        className={`d-flex flex-column ${headerStyles.account_modal_content}`}
      >
        <h2 className={headerStyles.account_modal_heading}>Forgot Password</h2>
        <p className={headerStyles.account_modal_option}>
          Enter your Registered Email
        </p>
        <form onSubmit={props.onSubmitForgot} data-test="login_form">
          <div className={headerStyles.input_section}>
            <div className={headerStyles.input_field}>
              <InputComponent
                data-test="user_phone/email"
                formControl={{
                  id: "user_phone/email",
                  placeholder: "Email *",
                  onChange: props.onInputforgotChange,
                  name: "email_phone",
                  type: "text",
                  "aria-describedby": "user_phone/email",
                  value: props.forgotForm ? props.forgotForm.email_phone : "",
                }}
              />
            </div>
            <span className="error" data-test="username_error">
              {props.formError && props.formError.email_phone !== ""
                ? props.formError.email_phone
                : ""}
            </span>
          </div>
          <div className="d-flex justify-content-between">
            <p className={headerStyles.account_modal_option_link}>
              <span className={headerStyles.account_modal_option_link_text}>
                Continue to
              </span>
              <a onClick={props.openModel}>
                <u>Login</u>
              </a>
            </p>
            <ButtonComponent
              data-test="sign_in_btn"
              data={{
                type: "submit",
                className: headerStyles.login_btn,
              }}
            >
              Send
            </ButtonComponent>
          </div>
        </form>
        <div
          className={`${headerStyles.account_modal_social} d-flex align-items-center mt-auto justify-content-center`}
        >
          <span className={headerStyles.account_modal_social_text}>
            Or Continue with
          </span>
          {/* <FBLoginComponent /> */}
          <GoogleLoginComponent />
        </div>
      </div>
    </ModalComponent>
  );
};
export default ForgotPasswordComponent;
