import React from "react";

export const ReactToastify = ({ closeToast, toastProps, title, desc }) => {
  return (
    <div>
      <h6>{title}</h6>
      <p>{desc}</p>
    </div>
  );
};
