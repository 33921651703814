import React, { useState } from "react";

import { message, Spin } from "antd";
import { useRouter } from "next/router";
import { GoogleLogin } from "react-google-login";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";

import { ReactToastify } from "../../helper/ReusableComponents";
import { onLogout, updateStateOnGoogleSignin } from "../../store/actions";
import { AxiosInstance, EncryptObjectData, logout } from "../../utilities";

export default function GoogleLoginComponent(props) {
  const [spin, setSpin] = useState(false);

  const router = useRouter();
  const dispatch = useDispatch();

  const responseGoogle = (response) => {
    setSpin(true);
    googleAUthentication(response);
  };

  const failureGoogle = (response) => {
    if (
      response &&
      (response.error === "idpiframe_initialization_failed" ||
        response.error === "popup_closed_by_user")
    ) {
      //
    } else {
      toast.error(<ReactToastify title="Error" desc="Invalid credentials" />);
    }
  };

  /**
   * @author Benakesha
   * @param {GOOGLE API RESPONSE} response
   * ON GOOGLE AUTHENTICATION HANDLER
   */
  const googleAUthentication = (response) => {
    AxiosInstance(4007, "application/json")
      .post("/auth/google/token", { id_token: response.tokenId })
      .then((resp) => {
        const response = resp && resp.data;
        if (
          response &&
          (response.status === "200" || response.status === 200)
        ) {
          setSpin(false);
          let data = response.userData
          localStorage.setItem("data",EncryptObjectData({...(response.userData), role: response.userData?.role?.trim()?.toLowerCase()?.replaceAll(" ","_")}))
          let token = response.token;
          localStorage.setItem("token", EncryptObjectData(token));
          toast.success(<ReactToastify title="Success" desc={response.msg} />);
          dispatch(updateStateOnGoogleSignin(token, {...(response.userData), role: response.userData?.role?.trim()?.toLowerCase()?.replaceAll(" ","_")}));
          setTimeout(() => {
            if(router.asPath !== window.location.pathname) router.push(window.location.pathname);
          }, 3000);
        } else if (
          response &&
          (response.status === 400 || response.status === "400")
        ) {
          setSpin(false);
          toast.error(<ReactToastify title="Error" desc={response.msg} />);
        }
      })
      .catch((err) => {
        var error = err && err.response;
        if (error && (error.status === "403" || error.status === 403)) {
          logout();
          dispatch(onLogout());
          setTimeout(() => {
            router.push(window.location.pathname);
          }, 500);
        } else if (error && (error.status === "500" || error.status === 500)) {
          setSpin(false);
          toast.error(
            <ReactToastify title="Error" desc="Internal Server Error" />
          );
        }
      });
  };
  return (
    <>
      <Spin spinning={spin} />
      <GoogleLogin
        className={"google_btn"}
        clientId={process.env.GOOGLE_SIGN_IN_KEY}
        render={(renderProps) => (
          <button className="img_btn">
            <img
              src="/assets/images/google_icon_modal.svg"
              alt="social_icons"
              onClick={renderProps.onClick}
              disabled={renderProps.disabled}
              width={27}
              height={27}
            />
          </button>
        )}
        onSuccess={responseGoogle}
        onFailure={failureGoogle}
        cookiePolicy={"single_host_origin"}
      />
    </>
  );
}
