import React, { useEffect, useState } from "react";

import { Tooltip } from "antd";
import moment from "moment";
import Image from "next/image";
import AutoComplete from "react-google-autocomplete";
import { useSelector } from "react-redux";

import {
  AntSelectComponent,
  AntDatePicker,
} from "../../helper/ReusableComponents";
import { AxiosInstance, logout } from "../../utilities";
import styles from "../HeaderComponent/Header.module.css";

const MegaSearchComponent = (props) => {

  const search = useSelector(state => state.search)
  const venue = search?.searchData?.event_place?.replace(/\+/g, " ")
  const [title, setTitle] = useState(search?.searchData?.event_place?.replace(/\+/g, " "))
  const [events, setEvents] = useState([]);


  useEffect(() => {
    const venue = search?.searchData?.event_place?.replace(/\+/g, " ")
    setTitle(venue)
  }, [search?.searchData?.event_place])

  const onChange = (place) => {
    props.onGooglePlaceSelect(place)
    setTitle(place.formatted_address)
  }

  useEffect(() => {
    fetchEvents()
  }, [])
  
  const fetchEvents = () => {
    AxiosInstance (4011, "application/json")
      .get("/eventTypes")
      .then((resp) => {
        const response = resp && resp.data;
        if (response.status === 200 || response.status === "200") {
          setEvents(response.data);
        }
      })
      .catch((err) => {
        var error = err && err.response;
        if (error && (error.status === "403" || error.status === 403)) {
          logout();
        } else if (error && (error.status === "500" || error.status === 500)) {
          console.log("Error", error.msg);
        }
      });
  };
  const onChangeDate = event => {
    const pickedDate = event;
    props.onDatePickerSelect(pickedDate);
  }

  const eventsList = events.sort(function(a, b) {
    return (a.name.toUpperCase() < b.name.toUpperCase()) ? -1 : (a.name.toUpperCase() > b.name.toUpperCase()) ? 1 : 0;
})
  return (
    <section className={`d-flex align-items-center ${styles.search_box}`}>
     {!props.hideInfoBtn && <Tooltip title="We need this basic information in order to retrieve availability and to calculate pricing.">
        <button className={`img_btn ${styles.info_icon}`}>
          <img
            src="/assets/images/info_icon.svg"
            alt="Info"
            width={32}
            height={32}
          />
        </button>
      </Tooltip>}
      <div
        className={`d-flex align-items-center ${styles.search_box_field_wrapper}`}
      >
        <div className={styles.search_box_field} id="search-date-input">
          <label className={styles.search_box_field_label}>Date of Event</label>
          <AntDatePicker
            datePickerProps={{
              className: `d-flex align-items-center justify-content_center border-less ${styles.search_box_field_dropdown}`,
              format: "MM/DD/YYYY",
              onChange:onChangeDate,
              disabledDate: props.disabledDate,
              // inputReadOnly: true,
              showNow: false,
              allowClear: false,
              value: props.headerSearch.event_date,
              onSelect: props.onDatePickerSelect,
              defaultPickerValue: moment().add(45, "days"),
            }}
          />
          {props.headerSearchError &&
            props.headerSearchError.event_date.trim() !== "" && (
              <span className="error">
                {props.headerSearchError.event_date}
              </span>
            )}
        </div>
        <div className={styles.search_box_field}>
          <label className={styles.search_box_field_label}>Venue or City</label>
          <Tooltip title={title}>
          <AutoComplete
            apiKey={process.env.GOOGLE_API_KEY}
            options={{
              types: ["establishment", "geocode"],
            }}
            onPlaceSelected={onChange}
            placeholder="Search for venue"
            defaultValue={props.headerSearch.event_place}
            className="search_box_field_auto"
            id="search-venue-input"
            onChange={props.onGooglePlaceChange}
          />
          {props.headerSearchError &&
            props.headerSearchError.event_place.trim() !== "" && (
              <span className="error">
                {props.headerSearchError.event_place}
              </span>
            )}
            </Tooltip>
        </div>
        <div className={styles.search_box_field}>
          <label className={styles.search_box_field_label}>Type of Event</label>
          <div className={`d-flex ${styles.search_box_field_input}`} id="search-event-input">
            <AntSelectComponent
              selectProps={{
                showArrow: false,
                onChange: props.onSelectChangeHandler,
                name: "event_type",
                placeholder: "Select an event",
                dropdownMatchSelectWidth: false,
                value: props.headerSearch && props.headerSearch.event_type,
                dropdownClassName: styles.search_box_field_drop,
                defaultValue: "wedding",
              }}
              fetching={false}
              className={`${styles.search_box_field_select}`}
              selectOptions={
              //   [
              //   { id: 1, name: "Wedding", value: "wedding" },
              //   { id: 2, name: "Corporate", value: "corporate" },
              //   { id: 3, name: "Birthday", value: "birthday" },
              //   { id: 3, name: "Mitzvah", value: "mitzvah" },
              // ]
              eventsList?.map((event) => (
                {id: event._id, name: event.name, value: event.slug}
              ))}
            />
          </div>
          {props.headerSearchError &&
            props.headerSearchError.event_type.trim() !== "" && (
              <span className="error">
                {props.headerSearchError.event_type}
              </span>
            )}
        </div>
      </div>
      {!props.showSearchText && <button
        className={`img_btn ${styles.search_btn} ${props.showSearchText ? styles.showSearchText : ""}`}
        onClick={props.onClickSearchBtn}
        id="run-search-btn"
      >
        <span>Search</span>
        <img
          src="/assets/images/search_icon_blue.svg"
          alt="Search"
          width={60}
          height={60}
          loading="lazy"
        />
      </button>}
    </section>
  );
};

export default MegaSearchComponent;
