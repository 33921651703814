import Loader from "react-loader-spinner";

import {
  ModalComponent,
  InputComponent,
  ButtonComponent,
} from "../../helper/ReusableComponents";
import styles from "../HeaderComponent/Header.module.css";
import FBLoginComponent from "../SocialLoginComponent/FBLoginComponent";
import GoogleLoginComponent from "../SocialLoginComponent/GoogleLoginComponent";

const LoginComponent = (props) => {
  return (
    <ModalComponent
      className={styles.account_modal}
      bodyStyle={{ display: "flex", padding: 0, justifyContent: "center" }}
      data={{
        visible: props.visible,
        onCancel: props.onCancelModal,
        footer: null,
      }}
    >
      <div className={styles.account_modal_banner}>
        <img
          loading="lazy"
          src="/assets/images/modal_banner.png"
          alt="Starlight"
          width={280}
          height={480}
        />
      </div>
      <div className={`d-flex flex-column ${styles.account_modal_content}`}>
        <h2 data-test="test-Login" className={styles.account_modal_heading}>
          Login
        </h2>
        <p className={styles.account_modal_option}>
          New to Starlight Music{" "}
          <a
            onClick={props.openSignUpModel}
            className={`${styles.account_modal_option_link} ${styles.account_modal_option_link_right} ${styles.account_modal_option_link_upper}`}
          >
            Sign up
          </a>
        </p>
        <form data-test="login_form" onSubmit={props.onLogin}>
          <div className={styles.input_section}>
            <div className={styles.input_field}>
              <InputComponent
                data-test="user_email"
                formControl={{
                  id: "user_email",
                  placeholder: "Email*",
                  onChange: props.onInputChange,
                  name: "email",
                  type: "text",
                  "data-test": "user_name",
                  value: props.loginForm ? props.loginForm.email.trim() : "",
                }}
              />
            </div>
            <span className="error" data-test="username_error">
              {props.formError && props.formError.email !== ""
                ? props.formError.email
                : ""}
            </span>
          </div>
          <div
            className={`${styles.input_section} ${styles.input_section_password}`}
          >
            <div className={styles.input_field}>
              <InputComponent
                formControl={{
                  placeholder: "Password*",
                  onChange: props.onInputChange,
                  name: "password",
                  type: props.pwd_visible ? "text" : "password",
                  "data-test": "password",
                  value: props.loginForm ? props.loginForm.password : "",
                }}
              />
              <img
                className={styles.eye_icon}
                src={
                  props.pwd_visible
                    ? "/assets/images/eye_close.svg"
                    : "/assets/images/eye_mini_open.svg"
                }
                alt=""
                onClick={props.on_password_visible}
                width={13}
                height={10}
              />
            </div>
            <span className="error" data-test="password_error">
              {props.formError && props.formError.password !== ""
                ? props.formError.password
                : ""}
            </span>
          </div>
          <div className="d-flex justify-content-between flex-wrap">
            <a
              onClick={props.onClickforgotModel}
              className={styles.account_modal_option_link}
            >
              <u>Forgot Password</u>
            </a>
            <div className="d-flex flex-column">
              <ButtonComponent
                data-test="sign_in_btn"
                data={{
                  type: "submit",
                  className: styles.login_btn,
                }}
              >
                Login 
                {props.loader ?
                <Loader
                height="15"
                width="20"
                color="white"
                ariaLabel="loading"
                type="Oval"
                style={{ marginLeft: "15px" }}
                className="mx-1"
              />
              : ''}
              </ButtonComponent>
              {/* <a
                onClick={props.onClickOtpModel}
                className={styles.account_modal_option_link}
              >
                Login With OTP
              </a> */}
            </div>
          </div>
        </form>
        <div
          className={`${styles.account_modal_social} d-flex align-items-center mt-auto justify-content-center`}
        >
          <span className={styles.account_modal_social_text}>
            Or Continue with
          </span>
          {/* <FBLoginComponent /> */}
          {/* <SocialLoginComponent /> */}
          <GoogleLoginComponent />
        </div>
      </div>
    </ModalComponent>
  );
};

export default LoginComponent;
