export const emailRegx =/^([^<>()\[\]\\.,;:\s@"][\w\.+\-]+)@([\w\-]+)((\.(\w){2,63}){1,2})$/;
// /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const passwordReg = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/;
export const phoneRegx = /^(\([0-9]{3}\) |[0-9]{3}-)[0-9]{3}-[0-9]{4}$/;
///^(\+\d{1,2}\s?)?1?\-?\.?\s?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/;
export const urlRegx = /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g;
export const userName = /^[a-zA-Z,'. ]*$/;
export const price = /^(?!0+$)\d{1,6}(\.\d{1,2})?$/;
export const note = /\S\w*/;
export const quantity = /^(?!0+$)\d{1,3}$/;
export const floatingZero = /^(?:0?[1-9]\d*(?:\.\d*[0-9]*)?)|(:?^0.[1-9]+0)$/;
export const zipcodeRegx=/^(?!0{5})\d{5,6}(?:[-\s]\d{4})?$/;
export const addressRegx=/^[#/.0-9a-zA-Z\s,-;'@(:)_]+$/;
export const companyNameRegx = /^(?!\s)(?!.*\s$)(?=.*[a-zA-Z0-9])[a-zA-Z0-9 '~?@&.!]{2,}$/;
export const otpRegx=/^\d{4,4}$/;
export const zipcode=/^[0-9]{6}(?:-[0-9]{4})?$/;
export const onlyNumber=/^[0-9]*$/;
export const USAphoneRegx = /^(\+\d{1,2}\s?)?1?\-?\.?\s?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/;
export const httpUrl=/^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/;