import React, { useState, useRef, useEffect } from "react";

import { DownOutlined } from "@ant-design/icons";
import { Popover } from "antd";
import { DatePicker, Select, message } from "antd";
import axios from "axios";
import moment from "moment";
import momentTimezone from "moment-timezone";
import Image from 'next/image' 
import Link from "next/link";
import router from "next/router";
import { useRouter } from "next/router";
import { Nav, Dropdown } from "react-bootstrap";
import { Modal as SearchModal } from "react-bootstrap";
import { InlineWidget } from "react-calendly";
import Autocomplete from "react-google-autocomplete";
import Modal from "react-modal";
import PhoneInput from "react-phone-input-2";
import { useSelector } from "react-redux";
import validator from "validator";

import styles from "./Header.module.css";
import { emailRegx, phoneRegx } from "../../helper/RegularExp/RegularExp";
import { InputComponent } from "../../helper/ReusableComponents";
import {
  checkIsObjectEmpty,
  getGooglePlaceObjectValues,
} from "../../helper/service";
import { AxiosInstance, logout } from "../../utilities";
import MegaSearchComponent from "../MegaSearchComponent/MegaSearchComponent";

const { Option } = Select;

const newFormData = {
  firstName: "",
  lastName: "",
  email: "",
  countryCode: "",
  phone: "",
  date: "",
  venue: "",
  eventType: "",
  bandId: "",
  lat: "",
  lng: "",
};

export function useOutsideAlerter(initialIsVisible) {
  const ref = useRef(null);
  const [menuOpen, setMenuOpen] = useState(initialIsVisible);

  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event) {
      var google_search = document.querySelectorAll(".pac-container");
      var googlePlace = "";
      for (var i = 0; i < google_search.length; i++) {
        if (
          !google_search[i].style.display ||
          google_search[i].style.display !== "none"
        ) {
          googlePlace = google_search[i];
        }
      }
      if (googlePlace === "") {
        googlePlace = document.querySelector(".pac-container");
      }
      if (
        ref.current &&
        !ref.current?.contains(event.target) &&
        !event.target.classList?.contains("ant-select-item-option-content") &&
        !googlePlace?.contains(event.target)
      ) {
        setMenuOpen(false);
      }
    }

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);
  return { ref, menuOpen, setMenuOpen };
}

const NavBarComponent = (props) => {
  const { query } = useRouter();
  const [formData, setFormData] = useState({ ...newFormData });
  const [formDataError, setFormDataError] = useState({
    firstName: "",
    lastName: "",
    email: "",
    venue: "",
    eventType: "",
    date: "",
    phone: "",
  });
  const auth = useSelector((state) => state.auth);
  const search = useSelector((state) => state.search);
  const [events, setEvents] = useState([]);
  const [bandname, setBandName] = useState([]);
  const [upcomingShowCase, setUpcomingShowCase] = useState(null);
  const [demoId, setDemoId] = useState("");
  const { ref, menuOpen, setMenuOpen } = useOutsideAlerter(false);

  const [isAuthenticated, setIsAuthenticate] = useState(false);
  const [timeZone, setTimeZone] = useState("");
  const [headerSearch, setHeaderSearch] = useState({
    event_date: "",
    event_type: "wedding",
    event_place: undefined,
  });
  const [headerSearchError, setHeaderSearchError] = useState({
    event_date: "",
    event_place: "",
    event_type: "",
  });
  const [eventPlace, setEventPlace] = useState({
    event_place: {
      lat: "",
      lng: "",
    },
  });

  const [show, setShow] = useState(false);
  const [showBandAvailabilityModal, setShowBandAvailabilityModal] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isCalendlyOpen, setIsCalendlyOpen] = useState(false);

  useEffect(() => {
    setShowBandAvailabilityModal(props.showModalForWeb)
  },[props.showModalForWeb])

  useEffect(() => {
    const checkQuery = Object.keys(query)
    if(checkQuery.length == 1 && checkQuery[0] == 'book-a-demo'){
      setIsModalOpen(true);
      setIsCalendlyOpen(true);
    }
    fetchEvents();
    fetchNavBarEssentials();
    window.addEventListener("message", calendlyCall);
    return () => {
    window.removeEventListener("message", calendlyCall);
    localStorage.removeItem("demoId")
    }

  }, []);

  const removeQueryParamsFromRouter = (router, removeList = []) => {
    if (removeList.length > 0) {
        removeList.forEach((param) => delete router.query[param]);
    } else {
        // Remove all
        Object.keys(object).forEach((param) => delete object[param]);
    }
    router.replace(
        {
            pathname: router.pathname,
            query: router.query
        },
        undefined,
        /**
         * Do not refresh the page
         */
        { shallow: true }
    );
};



  const calendlyCall = (e) => {
    if (
      isCalendlyEvent(e) &&
      e?.data?.event === "calendly.event_scheduled" &&
      e?.data?.payload?.invitee?.uri
    ) {
      calendlySubmit(e?.data?.payload?.invitee?.uri);
      if (typeof window !== 'undefined' && !["development"].includes(process.env.REACT_APP_NODE_ENV)) {
      window.gtag('event', 'Meetingconfirmed ', {
        'event_category': 'Bookademo',
        'event_label': 'Book a Demo Confirmed',
        'event_id': 'Goal ID 5 / Goal Set 1',
        'event_action': 'Meetingconfirmed'
      }); }
    }
  };

  useEffect(() => {
    if (auth && auth.token) {
      setIsAuthenticate(true);
    } else {
      setIsAuthenticate(false);
    }
  }, [auth]);

  useEffect(() => {
    setMenuOpen(false);
    if (search.searchData) {
      const headerSearchClone = { ...headerSearch };
      headerSearchClone.event_type = search.searchData.eventType;
      headerSearchClone.event_date = moment(search.searchData.date);
      headerSearchClone.event_place = search.searchData.event_place
        ? search.searchData?.event_place?.replace(/\+/g, " ")
        : "";
      setHeaderSearch(headerSearchClone);
      const eventPlaceClone = { ...eventPlace };
      eventPlaceClone.event_place.lat = search.searchData.latitude;
      eventPlaceClone.event_place.lng = search.searchData.longitude;
      eventPlaceClone.event_place.address = search.searchData.event_place
        ? search.searchData?.event_place?.replace("+", " ")
        : "";
      setEventPlace(eventPlaceClone);
    }
    setTimeZone(momentTimezone.tz.guess());
  }, [search]);

  const gotoMyAccount = () => {
    window.location.href = "/dashboard";
  };
  const faqhandlelcick = () => {
    window.open("/faq/how-to-use-starlight-music-online-software-platform/how-do-i-book-a-demo-presentation");
  }
  const toggleMenuOpen = () => {
    setMenuOpen(true);
  };
  

  const disabledDate = (current) => {
    return current && current < moment().add(45, "day");
  };

  const onDatePickerSelect = (date, dateString) => {
    const headerSearchClone = { ...headerSearch };
    const headerSearchErrorClone = { ...headerSearchError };
    headerSearchClone.event_date = moment(date._d);
    headerSearchErrorClone.event_date = "";
    setHeaderSearch(headerSearchClone);
    setHeaderSearchError(headerSearchErrorClone);
  };

  const onSelectChangeHandler = (value) => {
    const headerSearchClone = { ...headerSearch };
    const headerSearchErrorClone = { ...headerSearchError };
    headerSearchErrorClone.event_type = "";
    headerSearchClone.event_type = value;
    setHeaderSearch(headerSearchClone);
    setHeaderSearchError(headerSearchErrorClone);
  };

  const onClickSearchBtn = async (e) => {
    e.preventDefault();
    const headerSearchErrorClone = { ...headerSearchError };
    if (headerSearch.event_date === "") {
      headerSearchErrorClone.event_date = "Required";
    } else {
      headerSearchErrorClone.event_date = "";
    }
    if (
      Object.values(eventPlace.event_place).every((x) => x === "" || x === null)
    ) {
      headerSearchErrorClone.event_place = "Required";
    } else {
      headerSearchErrorClone.event_place = "";
    }

    if (
      headerSearch.event_type !== undefined &&
      headerSearch.event_type.trim() === ""
    ) {
      headerSearchErrorClone.event_type = "Required";
    } else if (headerSearch.event_type === undefined) {
      headerSearchErrorClone.event_type = "Required";
    } else {
      headerSearchErrorClone.event_type = "";
    }
    setHeaderSearchError(headerSearchErrorClone);

    const isEmpty = await checkIsObjectEmpty(headerSearchErrorClone);
    if (isEmpty) {
      const headerSearchClone = { ...headerSearch };
      const start_date = moment
        .tz(
          moment(headerSearch.event_date._d).format("YYYY-MM-DD 00:00:00"),
          timeZone
        )
        .utc();
      const end_date = moment
        .tz(
          moment(headerSearch.event_date._d).format("YYYY-MM-DD 23:59:59"),
          timeZone
        )
        .utc();

      let data = {
        startDate: start_date._d,
        endDate: end_date._d,
        eventType: headerSearchClone.event_type,
        latitude: eventPlace.event_place.lat,
        longitude: eventPlace.event_place.lng,
      };
      localStorage.setItem("searchData", JSON.stringify(data));

      const addressSlug = eventPlace.event_place.address
        .split(", ")
        .join("+")
        .split(" ")
        .join("+")
        .split(".")
        .join("")
        .split("-")
        .join("")
        .split("++")
        .join("+");
      const slug = `/search-results/${headerSearchClone.event_type}/${moment(
        start_date._d
      ).format("YYYY")}/${moment(start_date._d).format("MM")}/${moment(
        start_date._d
      ).format("DD")}/${addressSlug}/@${
        eventPlace.event_place.lat + "," + eventPlace.event_place.lng
      }`;

      router.push({
        pathname: slug,
        state: {
          searchData: data,
        },
      });
      handleClose();
    }
    if (typeof window !== 'undefined' && !["development", "local"].includes(process.env.REACT_APP_NODE_ENV)) {
    window.gtag('event', 'EventSearch', {
      'event_category': 'EventSearch',
      'event_label': 'Ran an Availability Search',
      'event_id': 'Goal ID 7 / Goal Set 2',
      'event_action': 'EventSearch'
    });    }
  };

  const onBookDemo = () => {
    router.push('?book-a-demo')
    setFormData({
      ...formData, 
      date : search?.searchData?.date,
      eventType: events?.find(ev => ev?.slug === search?.searchData?.eventType)?._id,
      lat: search?.searchData?.latitude,
      lng: search?.searchData?.longitude,
      venue: search?.searchData?.event_place?.replaceAll("+", " "),
    })
    setIsModalOpen(true)
  }

  const onGooglePlaceSelect = (place) => {
    let formatedData = getGooglePlaceObjectValues(place);
    const eventPlaceClone = { ...eventPlace };
    const addressSlug = formatedData.address
      .split(", ")
      .join("+")
      .split(" ")
      .join("+")
      .split(".")
      .join("")
      .split("-")
      .join("")
      .split("++")
      .join("+");
    eventPlaceClone.event_place.lat = formatedData.lat;
    eventPlaceClone.event_place.lng = formatedData.lng;
    eventPlaceClone.event_place.address = formatedData.address;
    const headerSearchErrorClone = { ...headerSearchError };
    headerSearchErrorClone.event_place = "";
    setEventPlace(eventPlaceClone);
    setHeaderSearchError(headerSearchErrorClone);
  };
  const onVenueChange = (place) => {
    const { lat, lng, address } = getGooglePlaceObjectValues(place);
    setFormData((fd) => ({ ...fd, lat: lat, lng: lng, venue: address }));
    setFormDataError({
      ...formDataError,
      venue: "",
    });
  };

  const onGoogleVenueChange = (e) => {
    setFormData({ ...formData, venue: e.target.value });
    setFormDataError({ ...formDataError, venue: "Please select venue" });
  };

  const formChangeHandler = (e) => {
    let { name, value } = e.target;
    value = value.replace(/^\s+/g, "");
    value =
      value.length === 1
        ? value.replace(" ", "")
        : value[value.length - 1] === " " && value[value.length - 2] === " "
        ? value.substring(0, value.length - 1)
        : value;
    if (value.length === 1)
      setFormDataError({
        ...formDataError,
        [name]: `Please provide more than 1 character`,
      });
    else setFormDataError({ ...formDataError, [name]: "" });
    if (name === "firstName" || name === "lastName") {
      value = value.replace(/[0-9]/gi, "");
      value = value.replace(/[^\w\s]/gi, "");
      if (value.length > 26) {
        message.warning("Only 26 characters allowed");
        value = value?.slice(0, 26);
      }
    }
    if (name === "email") {
      value = value.trim();
      let emailRegExp = new RegExp(emailRegx);
      if (
        (!validator.isEmail(value) || !emailRegExp.test(value)) &&
        value.length > 0
      )
        setFormDataError({
          ...formDataError,
          email: "Please enter valid email",
        });
      else setFormDataError({ ...formDataError, email: "" });
    }
    if (name === "phone") {
      if (isNaN(value)) return;
    }
    if (name === "message") {
      if (value.length > 500) message.warning("Only 500 characters allowed");
      value = value?.slice(0, 500);
    }
    setFormData({ ...formData, [name]: value });
  };

  const onNextClick = () => {
    removeQueryParamsFromRouter(router, ["book-a-demo"])
    const copyForm = { ...formData };
    const data = {
      firstName: copyForm?.firstName?.trim(),
      lastName: copyForm?.lastName?.trim(),
      phone: copyForm?.phone?.trim(),
      email: copyForm?.email?.trim(),
      date: copyForm?.date,
      venue: copyForm?.venue?.trim(),
      eventType: copyForm?.eventType,
      bandId: copyForm?.bandId,
      countryCode: copyForm?.countryCode,
    };
    if (JSON.stringify(copyForm) === JSON.stringify({ ...newFormData })) {
      setFormDataError({
        firstName: "Please provide first name",
        lastName: "Please provide last name",
        email: "Please provide email",
        venue: "Please provide venue",
        eventType: "Please provide event type",
        date: "Please select date",
        phone: "Please provide phone number",
      });
      return message.error("Please fill the form");
    }
    for (let [key, value] of Object.entries({ ...data })) {
      if (value === "" && key !== "bandId") {
        setFormDataError({
          ...formDataError,
          [key]: `Please provide ${key
            .replace(/([a-z])([A-Z])/g, "$1 $2")
            .toLocaleLowerCase()}`,
        });
        return message.error(
          `Please provide ${key
            .replace(/([a-z])([A-Z])/g, "$1 $2")
            .toLocaleLowerCase()}`
        );
      }
      if (value?.length <= 1 && key !== "bandId" && key !== "countryCode") {
        setFormDataError({
          ...formDataError,
          [key]: `Provide more than 1 character`,
        });
        return message.error(
          `Provide more than 1 character for ${key
            .replace(/([a-z])([A-Z])/g, "$1 $2")
            .toLocaleLowerCase()}`
        );
      }
    }
    // eslint-disable-next-line no-unused-vars
    for (let [key, value] of Object.entries(formDataError)) {
      if (value !== "") {
        return message.error(value);
      }
    }

    if (!data.bandId) delete data.bandId;

    setIsCalendlyOpen(true);
    localStorage.setItem("bookFormData", JSON.stringify(formData));
    handleSubmit()
    if (typeof window !== 'undefined' && !["development"].includes(process.env.REACT_APP_NODE_ENV)) {
    window.gtag('event', 'Meetinginfo', {
      'event_category': 'Bookademo',
      'event_label': 'Contact Info Submitted',
      'event_id': 'Goal ID 1 / Goal Set 1',
      'event_action': 'Meetinginfo'
    });}
  };

  const calendlySubmit = async (uri) => {

    const res = await axios.get(uri, {
      headers: {
        Authorization: process.env.CALENDLY_KEY,
        "Content-Type": "application/json",
      },
    });
    if(res?.data?.resource){
      console.log({res})
      const {event,cancel_url,name, questions_and_answers, email} = res?.data?.resource
      let demoDate = ""
      if(event){
        const eventRes = await axios.get(event, {
          headers: {
            Authorization: process.env.CALENDLY_KEY,
            "Content-Type": "application/json",
          },
        })
        demoDate = eventRes?.data?.resource?.start_time;
      }
      let eDate = questions_and_answers?.find((each) => each.position == 1)?.answer // Event date
      let data = {
        firstName: name?.split(" ")?.[0] ?? "",
        lastName: name?.split(" ")?.[1] ?? "",
        phone: questions_and_answers?.find((each) => each.position == 0)?.answer, // cell number
        email,
        date: eDate ? moment(eDate,"MM/DD/YYYY").format("YYYY-MM-DD") : demoDate ? moment(demoDate).format("YYYY-MM-DD") : "",
        demoDate: demoDate
      }
      AxiosInstance(4011, "application/json")
      .post(`/demo`, data)
      .then((resp) => {
        const response = resp?.data;
        if (response?.status == 200) {
          setFormData(newFormData);
          setIsModalOpen(false);
          setIsCalendlyOpen(false);
          window.scrollTo(0, 0);
          message.success(response?.msg, 4);
        } else if (response?.status == 400) {
          message.error(response.msg, 4);
        }
      })
      .catch((err) => {
        var error = err?.response;
        if (error?.status == 403) {
          logout();
        } else if (error?.status == 500) {
          message.error("Internal Server Error", 4);
        }
      });
    }
    // const demoDate = res?.data?.resource?.start_time;
    // console.log({res})
    // const idDemo = localStorage.getItem("demoId")

    

    // await AxiosInstance(4011, "application/json")
    //   .post(`/demo`, { date: demoDate })
    //   .then((resp) => {
    //     const response = resp?.data;
    //     if (response?.status == 200) {
    //       setFormData(newFormData);
    //       setIsModalOpen(false);
    //       setIsCalendlyOpen(false);
    //       window.scrollTo(0, 0);
    //       message.success(response?.msg, 4);
    //     } else if (response?.status == 400) {
    //       message.error(response.msg, 4);
    //     }
    //   })
    //   .catch((err) => {
    //     var error = err?.response;
    //     if (error?.status == 403) {
    //       logout();
    //     } else if (error?.status == 500) {
    //       message.error("Internal Server Error", 4);
    //     }
    //   });

  }
    const handleSubmit = async () => {
    const bookedData = { ...JSON.parse(localStorage.getItem("bookFormData")) };
    localStorage.removeItem("bookFormData");
    if (!bookedData?.bandId) delete bookedData?.bandId;
    if (!bookedData) return;
    if (Object.keys(bookedData).length === 0) return;

    await AxiosInstance(4011, "application/json")
      .post("/demo", { ...bookedData })
      .then((resp) => {
        const response = resp?.data;
        if (response?.status == 200) {
          setFormData(newFormData);
          setDemoId(response?._id)
          localStorage.setItem("demoId", response?._id)
          message.success(response?.msg, 4);
        } else if (response?.status == 400) {
          message.error(response.msg, 4);
        }
      })
      .catch((err) => {
        var error = err?.response;
        if (error?.status == 403) {
          logout();
        } else if (error?.status == 500) {
          message.error("Internal Server Error", 4);
        }
      });
  };

  const fetchEvents = () => {
    AxiosInstance(4011, "application/json")
      .get("/eventTypes")
      .then((resp) => {
        const response = resp && resp.data;
        if (response.status === 200 || response.status === "200") {
          setEvents(response.data);
        }
      })
      .catch((err) => {
        var error = err && err.response;
        if (error && (error.status === "403" || error.status === 403)) {
          logout();
        } else if (error && (error.status === "500" || error.status === 500)) {
          console.log("Error", error.msg);
        }
      });
  };

  const onPhoneInputChanges = (value, country, e, formattedValue) => {
    const { name } = e.target ? e.target : "";
    const formDataErrorNew = { ...formDataError };
    switch (name) {
      case "phone":
        formattedValue.trim() === ""
          ? (formDataError.phone = "")
          : !phoneRegx.test(formattedValue.trim()) &&
            formattedValue.trim() !== ""
          ? (formDataErrorNew.phone = "Please enter a valid phone number")
          : (formDataErrorNew.phone = "");
        break;
      default:
        break;
    }
    let phoneNo = formattedValue;
    let countryCode = "+" + e.target ? country.dialCode : value;
    setFormDataError(formDataErrorNew);
    setFormData({ ...formData, phone: phoneNo, countryCode });
  };

  const fetchNavBarEssentials = () => {
    AxiosInstance(4008, "application/json")
      .get("/navInfo")
      .then((resp) => {
        const response = resp && resp.data;
        if (
          response &&
          (response.status === 200 || response.status === "200")
        ) {
          const {featuredBands, upcomingShowcase} = response.data;
          setBandName(featuredBands);
          setUpcomingShowCase(upcomingShowcase || null)
        }
      })
      .catch((err) => console.log(err));
  };

  function isCalendlyEvent(e) {
    return e.data.event?.indexOf("calendly") === 0;
  }

  return (
    // Please add custom_navbar_open with old one
    <nav
      className={`w-100 justify-content-md-end ${styles.custom_navbar} ${
        menuOpen ? styles.custom_navbar_open : ""
      }`}
      ref={ref}
    >
      <div className="navbar">
        <div
          className={`w-100 d-flex align-items-center justify-content-lg-evenly mr-auto ${styles.nav_section_home}`}
        >
          <Link href="/venues">
            <a className={styles.nav_link}>At Your Venue</a>
          </Link>
          <Link href="/our-talent">
            <a className={styles.nav_link} id="browse-bands-link">Browse Bands</a>
          </Link>
          <Link href="/meet-the-team">
            <a className={styles.nav_link} id="meet-the-team-link"> Meet the Team</a>
          </Link>
          <span style={{right:"40px",position:"relative"}}>
          <Link href="/">
            <a className={`${styles.main_logo} ${styles.remove_unused_logo}`}>
              <Image
                src="/assets/logos/BlueStarlightMusic-Logo-CMYK-hires-transparent.webp"
                alt="Starlight Music"
                width={136}
                height={110}
                className={styles.header_brown_logo}
                priority
              />
              <Image
                src="/assets/logos/WhiteStarlightMusic-Logo-hires-transparent.webp"
                alt="Starlight Music"
                height={64}
                width={136}
                className={styles.header_logo}
              />
            </a>
          </Link>
          <Link href="/">
            <a className={`${styles.main_logo} ${styles.remove_unused_logo}`}>
              <Image
                src="/assets/logos/BlueSilverPro-Logo-CMYK-hires-transparent.png"
                alt="Silverpro Music"
                width={170}
                height={90}
                className={`${styles.header_brown_logo} ${styles.topSPLogo}`}
                priority
              />
              <Image
                src="/assets/logos/WhiteSilverPro-Logo-hires-transparent.png"
                alt="Silverpro Music"
                height={64}
                width={136}
                className={`${styles.header_logo} ${styles.topSPLogowhite}`}
              />
            </a>
          </Link>
          </span>
          {upcomingShowCase ?
            <Link href={`/showcases/${upcomingShowCase.slug}`}>
              <a className={styles.nav_link}>Showcase</a>
            </Link> :
            <Link href="/celebrities">
              <a className={styles.nav_link}>Celebrities</a>
            </Link>
          }
          <Link href="/contact-us">
            <a className={styles.nav_link} id="contact-us-link">Contact Us</a>
          </Link>
          {!isAuthenticated ? (
            <Nav.Link onClick={props.openModel} className={styles.nav_link} id="login-signup-btn">
              Login /Sign Up
            </Nav.Link>
          ) : (
            <Popover
              placement="bottom"
              content={
                <div className={styles.nav_section_profile_popover}>
                  <ul>
                    <li onClick={() => gotoMyAccount()}>My Account</li>
                    <li onClick={props.logout}>Logout</li>
                  </ul>
                </div>
              }
            >
              <div className={styles.nav_section_profile_wrapper}>
                <div className={styles.nav_section_profile_detail_container}>
                  <p className={styles.nav_section_profile_detail_welcomeText} id="welcome-btn">
                    Welcome
                    <DownOutlined />
                  </p>
                  <p className={styles.nav_section_profile_detail_username}>
                    {auth.userData?.firstName + " " + auth.userData?.lastName}
                  </p>
                </div>
                <div>
                  <img
                    src={
                      auth.userData?.profilePicture
                        ? process.env.IMAGE_LINK + auth.userData?.profilePicture
                        : `/assets/images/user_empty.svg`
                    }
                    alt="Profile"
                    width={26}
                    height={26}
                    className={styles.nav_section_profile_img}
                  />
                </div>
              </div>
            </Popover>
          )}
        </div>
        <div
          className={`w-100 d-flex justify-content-between mr-auto flex-wrap flex-lg-nowrap ${styles.nav_section} ${styles.nav_section_splogo}`}
        >
          <span>
          <Link href="/">
          <a className={`${styles.main_logo} ${styles.remove_unused_logo} ${menuOpen ? styles.menu_open_field : ""}`}>
            <Image
              src="/assets/logos/BlueStarlightMusic-Logo-CMYK-Horizontal-highres-transparent_reduced.webp"
              alt="Starlight Music"
              width={136}
              height={60}
              className={styles.header_brown_logo}
              priority
            />
            <Image
              src="/assets/logos/WhiteStarlightMusic-Logo-hires-transparent.webp"
              alt="Starlight Music"
              width={136}
              height={64}
              className={styles.header_logo}
            />
          </a>
          </Link>
          <Link href="/">
          <a className={`${styles.main_logo} ${styles.remove_unused_logo} ${menuOpen ? styles.menu_open_field : ""}`}>
            <Image
              src="/assets/logos/BlueSilverPro-Logo-CMYK-hires-transparent.png"
              alt="Silverpro Music"
              width={134}
              height={68}
              className={styles.header_brown_logo}
              priority
            />
            <Image
              src="/assets/logos/WhiteSilverPro-Logo-hires-transparent.png"
              alt="Silverpro Music"
              width={136}
              height={64}
              className={styles.header_logo}
            />
          </a>
          </Link>
          </span>
          <div className={styles.nav_section_search}>
            <div
              className={
                menuOpen
                  ? styles.nav_section_search_mini_open
                  : styles.nav_section_search_mini
              }
            >
              
                {!!search?.searchData &&
                !!search.searchData?.event_place &&
                !!search.searchData?.eventType ? (
                  <button
                className={`d-flex align-items-center ${styles.nav_section_search_mini_btn}`}
                type="button"
                style={{backgroundColor: 'white'}}
                onClick={toggleMenuOpen}
              >
                  <div className={styles.nav_section_search_mini_btn_text_wrap} style={{backgroundColor: 'white'}}>
                    <span style={{color: '#645767', backgroundColor: 'white'}}>{moment.utc(search?.searchData?.date?.replace(/\-/g, "/"),"YYYY/MM/DD").format("MM/DD/YYYY")}</span>
                    <span
                      className={
                        styles.nav_section_search_mini_btn_text_wrap_city
                      } style={{color: '#645767', backgroundColor: 'white'}}
                    >
                      {search?.searchData?.event_place?.replace(/\+/g, " ")}{" "}
                    </span>
                    <span style={{color: '#645767', backgroundColor: 'white'}}>
                      {/* {search?.searchData?.eventType?.charAt(0).toUpperCase() +
                        search?.searchData?.eventType?.slice(1)}{" "} */}
                        {(events?.find( event => event?.slug === search?.searchData?.eventType))?.name ?? search?.searchData?.eventType?.charAt(0).toUpperCase() +
                      search?.searchData?.eventType?.slice(1)+ " "}
                    </span>
                  </div>
                     <div
                  className={styles.nav_section_search_mini_btn_img}
                  data-icon="true"
                  data-testid="little-search-icon"
                >
                  <img
                    src="/assets/images/search_icon_blue.svg"
                    alt="search-icon"
                    width={32}
                    height={32}
                  />
                </div>
                  </button>
                ) : (
                  <button
                className={`d-flex align-items-center ${styles.nav_section_search_mini_btn}`}
                type="button"
                // style={{backgroundColor: 'white'}}
                onClick={toggleMenuOpen}
              >
                  <p
                    className={`w-100 mx-3 mb-0 ${styles.nav_section_search_mini_btn_text} ${styles.search_animation}`}
                    style={{ textAlign: 'center'}}
                    id="littleSearchLabel"
                  >
                    Click Here for Pricing <img src="/assets/images/search_icon_blue.svg" alt="search-icon" width={32} height={32} ></img>
                  </p>
                  </button>
                )}
            </div>
            <div
              className={
                menuOpen
                  ? styles.nav_section_search_mega_open
                  : styles.nav_section_search_mega
              }
            >
              <div className="w-100 d-flex align-items-center justify-content-between">
                <Link href="/venues">
                  <a className={styles.nav_link}>At Your Venue</a>
                </Link>
                <Link href="/our-talent">
                  <a className={styles.nav_link}>Browse Bands</a>
                </Link>
                <Link href="/meet-the-team">
                  <a className={styles.nav_link}>Meet the Team</a>
                </Link>
                <Link href="/">
                  <a className={styles.main_logo}>
                    <img
                      loading="lazy"
                      src="/assets/logos/WhiteStarlightMusic-Logo-CMYK-Horizontal-highres.png"
                      alt="StarLight Music"
                      width={136}
                      height={60}
                    />
                  </a>
                </Link>
                <Link href="/">
                  <a className={styles.main_logo}>
                    <img
                      loading="lazy"
                      src="/assets/logos/WhiteSilverPro-Logo-hires-transparent.png"
                      alt="Silverpro Music"
                      width={136}
                      height={60}
                    />
                  </a>
                </Link>
                <Link href="/about-us">
                  <a className={styles.nav_link}>About Us</a>
                </Link>
                <Link href="/contact-us">
                  <a className={styles.nav_link}>Contact Us</a>
                </Link>
                {!isAuthenticated ? (
                  <Nav.Link
                    onClick={props.openModel}
                    className={styles.nav_link}
                  >
                    Login /Sign Up
                  </Nav.Link>
                ) : (
                  <Popover
                    placement="bottom"
                    content={
                      <div className={styles.nav_section_profile_popover}>
                        <ul>
                          <li onClick={() => gotoMyAccount()}>My Account</li>
                          <li onClick={props.logout}>Logout</li>
                        </ul>
                      </div>
                    }
                  >
                    <div className={styles.nav_section_profile_wrapper}>
                      <div
                        className={styles.nav_section_profile_detail_container}
                      >
                        <p
                          className={
                            styles.nav_section_profile_detail_welcomeText
                          }
                        >
                          Welcome
                          <DownOutlined />
                        </p>
                        <p
                          className={styles.nav_section_profile_detail_username}
                        >
                          {auth.userData?.firstName +
                            " " +
                            auth.userData?.lastName}
                        </p>
                      </div>
                      <div>
                        <img
                          src={
                            auth.userData?.profilePicture
                              ? process.env.IMAGE_LINK +
                                auth.userData?.profilePicture
                              : `/assets/images/user_empty.svg`
                          }
                          alt="Profile"
                          width={26}
                          height={26}
                          className={styles.nav_section_profile_img}
                        />
                      </div>
                    </div>
                  </Popover>
                )}
              </div>
              <MegaSearchComponent
                headerSearch={headerSearch}
                headerSearchError={headerSearchError}
                disabledDate={disabledDate}
                onDatePickerSelect={onDatePickerSelect}
                onSelectChangeHandler={onSelectChangeHandler}
                onClickSearchBtn={onClickSearchBtn}
                onGooglePlaceSelect={onGooglePlaceSelect}
              />
            </div>
          </div>
          <div
            className={`d-flex align-items-center ${
              menuOpen ? styles.menu_open_field : ""
            }`}
          >
            <div>
              <button
                className={styles.book_demo}
                onClick={() => {
                  removeQueryParamsFromRouter(router, ["book-a-demo"]);
                  setIsModalOpen(true);
                  setIsCalendlyOpen(true)
                }}
                id="book-a-demo-btn"
              >
                Schedule a Zoom
              </button>
              <Modal
                ariaHideApp={false}
                isOpen={isModalOpen}
                style={{
                  content: {
                    position: "fixed",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    maxWidth: "1500px",
                    maxHeight: "90vh",
                  },
                  overlay: {
                    backdropFilter: "blur(12px)",
                    background: "#00000069 0% 0% no-repeat padding-box",
                    zIndex: 999,
                  },
                }}
                className={isCalendlyOpen ? styles.book_a_demo : styles.book_a_demo_form}
              >
                <div className="d-flex contact_wrap">
                  <div className="book-a-demo-container">
                    

                    <button className="img_btn book-demo-close-btn">
                      {" "}
                      <img
                        src="/assets/images/close_icon.svg"
                        alt="close-icon"
                        onClick={() => {
                          removeQueryParamsFromRouter(router, ["book-a-demo"])
                          setIsModalOpen(false);
                          setIsCalendlyOpen(false);
                        }}
                      />{" "}
                    </button>
                    {!isCalendlyOpen && (
                      <div>
                      <div>
                      <div className="row">
                      <div className="col-lg-4">
                        {" "}
                        <h3 className="contact-form-heading">Book a Demo</h3>
                      </div>
                      <div className="col-lg-8">
                        {" "}
                        <button onClick={faqhandlelcick} className={styles.faq_button_size}> FAQ
                        </button>
                      </div>
                    </div>
                        <div className="row">
                          <div className="col col-md-6 col-12 contact-form-input">
                            <label>
                              First Name <sup className="contact-sup">*</sup>
                            </label>
                            <InputComponent
                              formControl={{
                                placeholder: "First name",
                                onChange: formChangeHandler,
                                name: "firstName",
                                value: formData?.firstName,
                                type: "text",
                                id:"fname-input-field",
                              }}
                            />
                            <span className="error">
                              {formDataError.firstName}
                            </span>
                          </div>
                          <div className="col col-md-6 col-12 contact-form-input">
                            <label>
                              Last Name <sup className="contact-sup">*</sup>
                            </label>
                            <InputComponent
                              formControl={{
                                placeholder: "Last name",
                                onChange: formChangeHandler,
                                name: "lastName",
                                value: formData?.lastName,
                                type: "text",
                                id:"lname-input-field",
                              }}
                            />
                            <span className="error">
                              {formDataError.lastName}
                            </span>
                          </div>
                        </div>
                        <div className="row mt-3">
                          <div className="col col-md-6 col-12 contact-form-input">
                            <label>
                              Phone <sup className="contact-sup">*</sup>
                            </label>
                            <PhoneInput
                              inputProps={{
                                name: "phone",
                                id:"phone-input-field",
                              }}
                              disableCountryCode={true}
                              country={"us"}
                              onlyCountries={["us"]}
                              value={formData?.phone}
                              onChange={onPhoneInputChanges}
                            />
                            <span className="error">{formDataError.phone}</span>
                          </div>
                          <div className="col col-md-6 col-12 contact-form-input">
                            <label>
                              Email <sup className="contact-sup">*</sup>
                            </label>
                            <InputComponent
                              formControl={{
                                placeholder: "Email",
                                onChange: formChangeHandler,
                                name: "email",
                                value: formData?.email,
                                type: "text",
                                id:"email-input-field",
                              }}
                            />
                            <span className="error">{formDataError.email}</span>
                          </div>
                        </div>
                        <div className="row mt-3">
                          <div className="col col-md-6 col-12 contact-form-input">
                            <label>
                              Date of Event <sup className="contact-sup">*</sup>
                            </label>
                            <DatePicker
                              dropdownClassName={styles.demo_select}
                              id="date-input-field"
                              value={
                                formData?.date
                                  ? moment(formData?.date)
                                  : moment().add(46, "day")
                              }
                              format="MM/DD/YYYY"
                              disabledDate={disabledDate}
                              placeholder="MM/DD/YYYY"
                              onChange={(e) => {
                                if (e !== "" && e) {
                                  setFormDataError({
                                    ...formDataError,
                                    date: "",
                                  });
                                } else {
                                  setFormDataError({
                                    ...formDataError,
                                    date: "Please provide date",
                                  });
                                }
                                setFormData({
                                  ...formData,
                                  date:
                                    e && e._d
                                      ? moment(e._d)
                                          .format("YYYY/MM/DD")
                                          .replaceAll("/", "-")
                                      : "",
                                });
                              }}
                            />
                            <span className="error">{formDataError.date}</span>
                          </div>
                          <div className="col col-md-6 col-12 contact-form-input">
                            <label>
                              Venue <sup className="contact-sup">*</sup>
                            </label>
                            {/* <InputComponent
                              formControl={{
                                placeholder: "Venue",
                                onChange: formChangeHandler,
                                name: "venue",
                                value: formData?.venue,
                                type: "text",
                              }}
                            /> */}

                            <Autocomplete
                              id="venue-input-field"
                              apiKey={process.env.GOOGLE_API_KEY}
                              options={{
                                types: ["establishment", "geocode"],
                              }}
                              value={formData?.venue}
                              onPlaceSelected={onVenueChange}
                              className="form-control"
                              onChange={onGoogleVenueChange}
                            />
                            <span className="error">{formDataError.venue}</span>
                          </div>
                        </div>
                        <div className="row mt-3">
                          <div className="col col-md-6 col-12 contact-form-input">
                            <label>
                              Event Type <sup className="contact-sup">*</sup>
                            </label>
                            <Select
                              id="event-input-field"
                              dropdownClassName={styles.demo_select}
                              bordered={false}
                              placeholder="Select event"
                              value={formData?.eventType}
                              onChange={(value) => {
                                setFormDataError({
                                  ...formDataError,
                                  eventType: "",
                                });
                                setFormData({ ...formData, eventType: value });
                              }}
                            >
                              {events?.map((event) => (
                                <Option key={event._id} value={event._id}>
                                  {event.name}
                                </Option>
                              ))}
                            </Select>
                            <span className="error">
                              {formDataError.eventType}
                            </span>
                          </div>
                          <div className="col col-md-6 col-12 contact-form-input">
                            <label>Band Preferred (Optional)</label>
                            <Select
                              dropdownClassName={styles.demo_select}
                              placeholder="Select Band"
                              bordered={false}
                              onChange={(value) => {
                                setFormDataError({
                                  ...formDataError,
                                  bandId: "",
                                });
                                setFormData({ ...formData, bandId: value });
                              }}
                            >
                              {bandname?.map((band) => (
                                <Option key={band._id} value={band._id}>
                                  {band.name}
                                </Option>
                              ))}
                            </Select>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-xs-12 book-demo-right-btn">
                        <button
                          className="book-demo-next-btn"
                          id="book-a-demo-next"
                          onClick={onNextClick}
                        >
                          Next
                        </button>
                        </div>
                      </div>
                      </div>
                    )}
                    {isCalendlyOpen && (
                      <div >
                        <p>Welcome to Starlight Music scheduling...</p>
                        <div className="calendly-container">
                        <InlineWidget
                          url={process.env.CALENDLY_URL}
                          // styles={{ margin: "600" }}
                        />
                        </div>
                        {/* <ButtonComponent
                      data={{
                        type: "button",
                        className: "book-a-demo-btn mt-3",
                        onClick: handleSubmit
                      }}>
                      {submitting ? (
                        <Spin style={{ color: "white" }} />
                      ) : (
                        <span>Book a demo</span>
                      )}
                    </ButtonComponent> */}
                      </div>
                    )}

                    {/* <button className="contact-us-submit-btn mt-3" onClick={handleSubmit}>{submitting ? <Spin indicator={antIcon} style={{ color: "white" }} /> :"Submit"}</button> */}
                  </div>

                  {/* <div className="contact-img">
                <div className="cnt-img-sticky">
                  <img
                    src={process.env.IMAGE_LINK + contactUsCms?.img}
                    alt="contact_us_img"
                    className="contact-us-img"
                  />
                  <div className="contact-img-bg"></div>
                  <p className="cnt-us-img-text">
                    {" "}
                    <Interweave
                      allowElements={false}
                      content={contactUsCms?.img_text}
                    />
                  </p>
                </div>
              </div> */}
                </div>
              </Modal>
            </div>
            <div className="dropdown">
              <Dropdown>
                <Dropdown.Toggle
                  variant="success"
                  id="dropdown-basic"
                  className={`img_btn ${styles.menu_btn}`}
                >
                  <img
                    src="/assets/images/menu.svg"
                    alt="Menu"
                    width={48}
                    height={48}
                  />
                </Dropdown.Toggle>
                <Dropdown.Menu className={styles.menu_dropdown}>
                  {isAuthenticated && (
                    <>
                      <img
                        src={
                          auth.userData?.profilePicture
                            ? process.env.IMAGE_LINK +
                              auth.userData?.profilePicture
                            : `/assets/images/user_empty.svg`
                        }
                        alt="Profile"
                        width={48}
                        height={48}
                        className={styles.menu_dropdown_profile}
                      />

                      <h5 className={styles.menu_dropdown_name}>
                        Hi{" "}
                        {auth.userData?.firstName +
                          " " +
                          auth.userData?.lastName}
                        !
                      </h5>
                      {/* <p className="menu_dropdown_mail">{auth.userData.email}</p>  */}
                    </>
                  )}
                  <Dropdown.Item>
                    <Link href="/">
                      <a className={`text-dark ${styles.nav_link}`}>Home</a>
                    </Link>
                  </Dropdown.Item>
                  <Dropdown.Item>
                    <Link href="/venues">
                      <a className={`text-dark ${styles.nav_link}`}>At Your Venue</a>
                    </Link>
                  </Dropdown.Item>
                  {/* <Dropdown.Item>
                  <Link href="/our-talent">
                      <a className={`text-dark ${styles.nav_link}`}>
                        Our Talent
                      </a>
                    </Link>
                    
                  </Dropdown.Item> */}
                  <Dropdown.Item>
                    <Link href="/meet-the-team">
                      <a className={`text-dark ${styles.nav_link}`}>
                        Meet the Team
                      </a>
                    </Link>
                  </Dropdown.Item>
                  {upcomingShowCase ? (<Dropdown.Item>
                    <Link href={`/showcases/${upcomingShowCase.slug}`}>
                      <a className={`text-dark ${styles.nav_link}`}>Showcase</a>
                    </Link>
                  </Dropdown.Item>) : null}
                  <Dropdown.Item>
                    <Link href="/celebrities">
                      <a className={`text-dark ${styles.nav_link}`}>
                        Celebrities
                      </a>
                    </Link>
                  </Dropdown.Item>
                  <Dropdown.Item>
                    <Link href="/our-talent">
                      <a className={`text-dark ${styles.nav_link}`}>About Us</a>
                    </Link>
                  </Dropdown.Item>
                  <Dropdown.Item>
                    <Link href="/contact-us">
                      <a className={`text-dark ${styles.nav_link}`}>
                        Contact Us
                      </a>
                    </Link>
                  </Dropdown.Item>
                  <Dropdown.Item>
                  <Link href="/faq">
                      <a className={`text-dark ${styles.nav_link}`}>FAQ</a>
                    </Link>
                  </Dropdown.Item>
                  <Dropdown.Item>
                      <a 
                        // onClick={onBookDemo} 
                        onClick={() => {
                          removeQueryParamsFromRouter(router, ["book-a-demo"]);
                          setIsModalOpen(true);
                          setIsCalendlyOpen(true)
                        }}
                        className={`text-dark ${styles.nav_link} ${styles.checkLink}`}>
                          Schedule a Zoom
                      </a>
                  </Dropdown.Item>
                  {!isAuthenticated ? (
                    <Dropdown.Item
                      className={styles.menu_dropdown_auth}
                      onClick={props.openModel}
                    >
                      Login / Sign Up
                    </Dropdown.Item>
                  ) : (
                    <>
                      <Dropdown.Item id="my-account-link" onClick={() => gotoMyAccount()}>
                        My Account
                      </Dropdown.Item>
                      <Dropdown.Item
                        id="logout-link"
                        className={styles.menu_dropdown_auth}
                        onClick={props.logout}
                      >
                        Logout
                      </Dropdown.Item>
                    </>
                  )}
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>
          <div className={styles.nav_section_search_mini_btn_mobile}>            
              {!!search?.searchData &&
               !!search.searchData?.event_place &&
               !!search.searchData?.eventType ? (
                <button
              className={`d-flex align-items-center ${styles.nav_section_search_mini_btn}`}
              type="button"
              style={{backgroundColor: 'white'}}
              onClick={handleShow}
                >
                <div className={styles.nav_section_search_mini_btn_text_wrap_mobile}>   
                  <span className="slash">
                    {moment.utc(search?.searchData?.date?.replace(/\-/g, "/"),"YYYY/MM/DD").format("MM/DD/YYYY")}
                  </span>
                  {/* <span className="nav_section_search_mini_btn_text_wrap_city">{search.searchData.event_place.replace(/\+/g, ' ')} </span> */}
                  <span>
                    {/* {search?.searchData?.eventType?.charAt(0).toUpperCase() +
                      search?.searchData?.eventType?.slice(1)}{" "} */}
                    {(events?.find( event => event?.slug === search?.searchData?.eventType))?.name ?? search?.searchData?.eventType?.charAt(0).toUpperCase() +
                      search?.searchData?.eventType?.slice(1)+ " "}
                  </span>
                </div>
                <div
                className={styles.nav_section_search_mini_btn_img}
                data-icon="true"
                data-testid="little-search-icon"
                >
                  <img
                    src="/assets/images/search_icon_blue.svg"
                    alt="Search"
                    width={29}
                    height={29}
                  />
                </div>
              </button>
              ) : (
                <button
              className={`d-flex align-items-center ${styles.nav_section_search_mini_btn}`}
              type="button"
              onClick={handleShow}
                >
                 <p
                  className={`w-100 mx-3 mb-0 ${styles.nav_section_search_mini_btn_text} ${styles.search_animation}`}
                  style={{textAlign: 'center'}}
                  id="littleSearchLabel-small"
                 >
                  Click Here for Pricing <img src="/assets/images/search_icon_blue.svg" alt="Search" width={29} height={29}/>
                 </p>
                </button>
              )}            
          </div>
        </div>
      </div>
      <SearchModal
        show={showBandAvailabilityModal}
        onHide={() => setShowBandAvailabilityModal(false)}     
        className={styles.search_modal}
        centered
        footer={false}
      >
        <SearchModal.Body>
          <h5>Did you check availability and pricing?</h5>
          <button
            className={`img_btn ${styles.search_modal_close}`}
            onClick={() => setShowBandAvailabilityModal(false)}
          >
            X
          </button>
          <MegaSearchComponent
            headerSearch={headerSearch}
            headerSearchError={headerSearchError}
            disabledDate={disabledDate}
            onDatePickerSelect={onDatePickerSelect}
            onSelectChangeHandler={onSelectChangeHandler}
            onClickSearchBtn={onClickSearchBtn}
            onGooglePlaceSelect={onGooglePlaceSelect}
            hideInfoBtn={true}
          />
        </SearchModal.Body>
      </SearchModal>

      <SearchModal
        show={show || !!props.showSearchModal}
        onHide={handleClose}
        className={styles.search_modal}
        centered
        footer={false}
      >
        <SearchModal.Body>
          <button
            className={`img_btn ${styles.search_modal_close}`}
            onClick={handleClose}
          >
            X
          </button>
          <MegaSearchComponent
            headerSearch={headerSearch}
            headerSearchError={headerSearchError}
            disabledDate={disabledDate}
            onDatePickerSelect={onDatePickerSelect}
            onSelectChangeHandler={onSelectChangeHandler}
            onClickSearchBtn={onClickSearchBtn}
            onGooglePlaceSelect={onGooglePlaceSelect}
          />
        </SearchModal.Body>
      </SearchModal>
    </nav>
  );
};

export default NavBarComponent;
