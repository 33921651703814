import React from "react";

import { Table, Empty } from "antd";
import Loader from "react-loader-spinner";

const AntTableComponent = (props) => {
  return (
    <Table
      {...props.tableData}
      locale={{
        emptyText: (
          <div style={{ textAlign: "center" }}>
            {props.isLoading && props.tableData.dataSource.length === 0 ? (
              <Loader
                type="ThreeDots"
                color="#00A6E4"
                height={50}
                width={50}
                visible={props.isLoading}
              />
            ) : (
              <Empty />
            )}
          </div>
        ),
      }}
    />
  );
};
export default AntTableComponent;