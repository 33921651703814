import React from "react";

import { Select, Empty } from "antd";
import Loader from "react-loader-spinner";

export const AntSelectComponent = (props) => (
  <Select
    {...props.selectProps}
    notFoundContent={
      props.fetching && props.selectOptions.length === 0 ? (
        <Loader
          type="ThreeDots"
          color="#00A6E4"
          height={50}
          width={50}
          visible={props.fetching}
        />
      ) : (
        <Empty />
      )
    }
  >
    {props.selectOptions &&
      props.selectOptions.length > 0 &&
      props.selectOptions.map((item, index) => (
        <Select.Option key={index} value={item.value}>
          {item.name}
        </Select.Option>
      ))}
  </Select>
);
