import React from "react";

import Modal from "antd/lib/modal/Modal";

export const AntModalComponent = (props) => {
  return (
    <Modal
      {...props.data}
      className={props.className}
      bodyStyle={props.bodyStyle}
      wrapClassName={props.wrapClassName}
    >
      {props.children}
    </Modal>
  );
};
