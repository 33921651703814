import OtpInput from "react-otp-input";

import {
  ButtonComponent,
  ModalComponent,
} from "../../helper/ReusableComponents";
import styles from "../HeaderComponent/Header.module.css";
import FBLoginComponent from "../SocialLoginComponent/FBLoginComponent";

const OtpVerifyComponent = (props) => {
  return (
    <ModalComponent
      className={styles.account_modal}
      bodyStyle={{ display: "flex", padding: 0, justifyContent: "center" }}
      data={{
        visible: props.visible,
        onCancel: props.onCancelModal,
        footer: null,
      }}
    >
      <div className={styles.account_modal_banner}>
        <img
          loading="lazy"
          src="/assets/images/modal_banner.png"
          alt="Starlight"
          width={280}
          height={480}
        />
      </div>
      <div className={`d-flex flex-column ${styles.account_modal_content}`}>
        <h2 className={styles.account_modal_heading}>Verify OTP</h2>
        <p className={styles.account_modal_option}>
          Please enter the OTP send to your Phone Number
        </p>
        <form onSubmit={props.onSubmitOtp} data-test="login_form">
          <div
            className={`${styles.input_section} ${styles.input_section_otp}`}
          >
            <div className={styles.input_field}>
              <OtpInput
                className={styles.otp_input_form}
                isInputNum={true}
                numInputs={4}
                separator={<span></span>}
                onChange={props.otpCodeChange}
                value={props.otpForm ? props.otpForm.otp : ""}
              />
            </div>
            <span className="error" data-test="username_error">
              {props.formError && props.formError.otp !== ""
                ? props.formError.otp
                : ""}
            </span>
          </div>
          <div className="d-flex justify-content-between flex-wrap">
            <div>
              <a
                onClick={props.resend_otp}
                className={`${styles.account_modal_option_link} ${styles.account_modal_option_link_resend}`}
              >
                <u>Resend OTP</u>
              </a>
              <p className={styles.account_modal_option_link}>
                <span className={styles.account_modal_option_link_text}>
                  Continue to
                </span>
                <a onClick={props.openSignUpModel}>
                  <u>Sign Up</u>
                </a>
              </p>
            </div>
            <ButtonComponent
              data-test="sign_in_btn"
              data={{
                type: "submit",
                className: styles.login_btn,
              }}
            >
              Verify
            </ButtonComponent>
          </div>
        </form>
        <div
          className={`${styles.account_modal_social} d-flex align-items-center mt-auto justify-content-center`}
        >
          <span className={styles.account_modal_social_text}>
            Or Continue with
          </span>
          {/* <FBLoginComponent /> */}
          {/* <SocialLoginComponent /> */}
        </div>
      </div>
    </ModalComponent>
  );
};
export default OtpVerifyComponent;
